import Header from "../../layout/Header";
import Navbar from "../../layout/Navbar";
import Button from "@mui/material/Button";
import WeightGraph from "./WeightGraph";
import CaloriesGraph from "./CaloriesGraph";
import { useLoaderData } from "react-router-dom";
import { Divider } from "@mui/material";

export default function Dashboard() {
  const data = useLoaderData();
  return (
    <div>
      <Header />
      <div className="flex flex-col px-4 py-8 mb-20">
        <WeightGraph chartData={data} />
        <Divider light className="py-4" />
        <div className="py-4" />
        <CaloriesGraph chartData={data} />
      </div>
      <Navbar />
    </div>
  );
}
