import Header from "../../layout/Header";
import Navbar from "../../layout/Navbar";
import AddTodayDetails from "./AddTodayDetails";
import AddFoodDetail from "./AddFoodDetails";
import Divider from "@mui/material/Divider";

export default function DetailsInput() {
  return (
    <div>
      <Header />
      <AddTodayDetails />
      <Divider light />
      <AddFoodDetail />
      <Navbar />
    </div>
  );
}
