import { collection, addDoc } from "firebase/firestore";
import { db } from "../utils/dbConnection";

export async function insertTodayDetails(weight, calories) {
  let today = new Date().toLocaleDateString();
  const docRef = await addDoc(collection(db, "DayTracker"), {
    date: today,
    weight: weight,
    calories: calories,
  });
  console.log("Document written with ID: ", docRef.id);
}

export async function insertFoodDetails(name, calories) {
  const docRef = await addDoc(collection(db, "Food"), {
    name: name,
    calories: calories,
  });
  console.log("Document written with ID: ", docRef.id);
}
