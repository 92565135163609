import Header from "../../layout/Header";
import Navbar from "../../layout/Navbar";
import CaloriesTable from "./CaloriesTable";
import IntakeHistoryTable from "./IntakeHistoryTable";

export default function Info() {
  return (
    <div>
      <Header />
      <div className="w-full text-2xl flex justify-center items-center p-6">
        <h1>Nutrition Details</h1>
      </div>
      <CaloriesTable />
      <div className="w-full text-2xl flex justify-center items-center p-6">
        <h1>History</h1>
      </div>
      <IntakeHistoryTable />
      <Navbar />
    </div>
  );
}
