import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../utils/dbConnection";

export default async function login(email, password) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user.email);
      if (user.email === "lgvishalsangam@gmail.com") {
        localStorage.setItem("kinetix", "yes");
      } else {
        localStorage.setItem("kinetix", "no");
      }
      // ...
      window.location.href = "/dashboard";
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
}

export function logout() {
  localStorage.removeItem("kinetix");
  window.location.href = "/";
}
