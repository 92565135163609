import { Fragment } from "react";
import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { insertFoodDetails } from "../../services/AddDetails";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddFoodDetail() {
  const [name, setName] = useState("");
  const [calories, setCalories] = useState("");

  const AddFoodHandler = () => {
    handleClick();
    insertFoodDetails(name, calories);
    setName("");
    setCalories("");
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Fragment>
      <div className="w-full text-2xl flex justify-center items-center p-6">
        <h1>Add Food Details</h1>
      </div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
        className="w-full flex justify-center items-center py-2"
      >
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Calories"
          variant="outlined"
          value={calories}
          onChange={(e) => setCalories(e.target.value)}
        />
        <Button variant="contained" onClick={AddFoodHandler}>
          Add
        </Button>
      </Box>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
          Successfully Added!
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
