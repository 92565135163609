import * as React from "react";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Alert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import { getAllFood } from "../../services/GetDetails";
import { getFoodByName } from "../../services/GetDetails";

export default function CaloriesTable() {
  const [loading, setLoading] = useState(true);
  const [food, setFood] = useState([]);
  useEffect(() => {
    getAllFood().then((response) => {
      setFood(response);
      setLoading(false);
    });
  }, []);

  const [searchVal, setSearchVal] = useState("");
  const searchHandler = () => {
    getFoodByName(searchVal).then((response) => {
      setFood(response);
    });
  };
  const resetHandler = () => {
    setLoading(true);
    getAllFood().then((response) => {
      setFood(response);
      setLoading(false);
    });
    setSearchVal("");
  };

  if(loading) {
    return(
      <div className="flex w-full justify-center">
        <CircularProgress />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="w-full flex justify-center items-center">
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
          className="w-3/5 flex justify-center items-center"
        >
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Box>
        <SearchIcon className="w-1/5 mx-2" onClick={searchHandler} />
        <RotateLeftIcon className="w-1/5 mx-2" onClick={resetHandler} />
      </div>
      {food.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table" className="w-full">
            <TableHead>
              <TableRow>
                <TableCell>Food</TableCell>
                <TableCell align="right">Calories</TableCell>
                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {food.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.calories}</TableCell>
                  {/* <TableCell align="right">{row.protein}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="w-full">
          <Alert severity="warning">No Data Found!</Alert>
        </div>
      )}
    </React.Fragment>
  );
}
