import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useRef, useEffect } from "react";
import login from "../../services/auth";
import NET from 'vanta/dist/vanta.net.min'

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const LoginHandler = async () => {
    login(email, password);
  };

  // Background setup
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  useEffect(() => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
    if (!vantaEffect) {
      setVantaEffect(NET({
        el: myRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: height,
        minWidth: width,
        scale: 1.00,
        scaleMobile: 1.00,
        // color: 0x55b5bb,
        color: 0x1b6bb3,
        backgroundColor: 0xffffff,
        spacing: 30.00
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect, height, width])

  return (
    <React.Fragment>
      <div ref={myRef}></div>
      <Box
        className="flex flex-col justify-center items-center h-screen"
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="outlined-password-input"
          type="password"
          label="Password"
          variant="outlined"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" onClick={LoginHandler}>
          Login
        </Button>
      </Box>
    </React.Fragment>
  );
}
