import { useState } from "react";
import BarChart from "../../components/BarChart";

export default function CaloriesGraph({ chartData }) {
  const [data, setData] = useState({
    labels: chartData.map((data) => data.date),
    datasets: [
      {
        label: "Calories",
        data: chartData.map((data) => data.calories),
      },
    ],
  });
  return (
    <div className="w-full max-h-96">
      <BarChart chartData={data} />
    </div>
  );
}
