import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import BarChartIcon from "@mui/icons-material/BarChart";
import AddchartIcon from "@mui/icons-material/Addchart";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate, useLocation } from "react-router-dom";

export default function Navbar() {
  const [value, setValue] = React.useState("");
  const location = useLocation();

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  };

  React.useEffect(() => {
    const currPath = location.pathname.substring(1);
    setValue(currPath.toProperCase());
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue("");
  };

  const navigate = useNavigate();

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className="w-full fixed bottom-0"
    >
      <BottomNavigationAction
        label="Dashboard"
        value="Dashboard"
        icon={<BarChartIcon />}
        onClick={() => navigate("/dashboard")}
      />
      <BottomNavigationAction
        label="Add"
        value="Add"
        icon={<AddchartIcon />}
        onClick={() => navigate("/add")}
      />
      <BottomNavigationAction
        label="Info"
        value="Info"
        icon={<InfoIcon />}
        onClick={() => navigate("/info")}
      />
    </BottomNavigation>
  );
}
