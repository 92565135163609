import { query, orderBy, collection, getDocs, where } from "firebase/firestore";
import { db } from "../utils/dbConnection";

export async function getAllFood() {
  const foodRef = collection(db, "Food");
  const q = query(foodRef, orderBy("name"));
  const data = await getDocs(q);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

export async function getHistory() {
  const historyRef = collection(db, "DayTracker");
  const q = query(historyRef, orderBy("date", "desc"));
  const data = await getDocs(q);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

export async function getHistoryAscending() {
  const historyRef = collection(db, "DayTracker");
  const q = query(historyRef, orderBy("date", "asc"));
  const data = await getDocs(q);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

export async function getFoodByName(searchVal) {
  const foodRef = collection(db, "Food");
  const q = query(foodRef, where("name", "==", searchVal));
  const data = await getDocs(q);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}
