import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createRoot } from "react-dom/client";

import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import DetailsInput from "./pages/detailsInput/DetailsInput";
import Info from "./pages/info/Info";
import { getHistoryAscending } from "./services/GetDetails";

const HistoryLoader = async () => {
  const data = await getHistoryAscending();
  return data;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element:
      localStorage.getItem("kinetix") === "yes" ? <Dashboard /> : <Login />,
    loader: HistoryLoader,
  },
  {
    path: "/add",
    element:
      localStorage.getItem("kinetix") === "yes" ? <DetailsInput /> : <Login />,
  },
  {
    path: "/info",
    element: localStorage.getItem("kinetix") === "yes" ? <Info /> : <Login />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
